// @flow
import React, { useState, useEffect } from 'react';
import Button from 'antd/es/button';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Radio from 'antd/es/radio';
import { useDispatch, useSelector } from 'react-redux';
import uid from 'uid';
import Tooltip from 'antd/es/tooltip';
import { fetchStacks } from '../../../../../store/duck/stacks';
import { HEADER_REGULATION_TEXT, GVL_VERSION_2, GVL_VERSION_3 } from '../../../../../utils/constants';
import SITETOOLTIPS from '../../../../../utils/toolTipsMessages';
import Stacks from './components/stacks';
import RecommendedList from '../../../../ui/recommended-list/recommendedList';
import Styles from '../../../../ui/recommended-list/purposesV2.module.scss';
import Loader from '../../../../ui/loader';
import Modal from '../../../../ui/modal';

import InfoIconTooltip from '../../../../ui/infoIconTooltip';
import Restrictions from './components/restrictions';

type Props = {
  purposeIds: Array<number>;
  flexiblePurposes: Array<number>;
  purposeLegitimateIds: Array<number>;
  specialFeatures: Array<number>;
  stackIds: Array;
  specialPurposes: Array;
  features: Array;
  use: string;
  gvlVersion: string;
  onChange: Function;
  onChangeTCFVersion: Function;
  userSelectedStack: Boolean;
  publisherConsentRestrictionIds: Array<number>;
  publisherLIRestrictionIds: Array<number>;
  vendorsData: Record<string, any>;
}

const PurposesV2 = ({
  purposeIds,
  purposeLegitimateIds,
  specialFeatures,
  stackIds,
  specialPurposes,
  features,
  use,
  gvlVersion,
  onChange,
  flexiblePurposes,
  userSelectedStack,
  publisherConsentRestrictionIds,
  publisherLIRestrictionIds,
  onChangeTCFVersion,
  vendorsData,
}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [restrictions, setRestrictions] = useState({
    publisherConsentRestrictionIds,
    publisherLIRestrictionIds
  });
  const [restrictionsModal, setRestrictionsModal] = useState(false);
  const [callRecommendationStacks, setCallRecommendationStacks] = useState(false);
  const [stackList, setStackList] = useState([]);
  const [selectedStackId, setSelectedStackId] = useState(0);
  const [selectedStack, setSelectedStack] = useState([]);
  const dispatch = useDispatch();
  const { pending, list } = useSelector(state => state.stacks);
  const preview = useSelector(state => state.preview);
  const noStackConfig = {
    publisherPurposeConsentIds: purposeIds,
    publisherSpecialFeaturesIds: specialFeatures,
    stacks: [],
    publisherPurposeLegitimateInterestIds: purposeLegitimateIds,
    specialPurposes,
    features
  };
  let content;

  const addSpecialPurposes = item => ({
    ...item,
    specialPurposes,
    features
  });

  const fetchRecommendedStacks = () => {
    dispatch(
      fetchStacks(
        purposeIds,
        purposeLegitimateIds,
        specialFeatures
      )
    );
  };

  useEffect(() => {
    if (use === 'stacks' && callRecommendationStacks && !list.length) {
      fetchRecommendedStacks();
    }
  }, [preview]);


  useEffect(() => {
    if (use === 'stacks' && !callRecommendationStacks) {
      setCallRecommendationStacks(true);
      fetchRecommendedStacks();
    }
  }, [use, gvlVersion]);

  useEffect(() => {
    setStackList(list.map(addSpecialPurposes));
  }, [list, preview]);

  useEffect(() => {
    setRestrictions({
      publisherConsentRestrictionIds,
      publisherLIRestrictionIds
    });
  }, [publisherConsentRestrictionIds, publisherLIRestrictionIds, preview]);
  if (use === 'stacks') {
    if (!pending && list.length) {
      let stackToUse = userSelectedStack
        ? {
          ...stackList.find((item, index) => {
            const isSelectedStacks = JSON.stringify(item.stacks) === JSON.stringify(stackIds);
            if (isSelectedStacks && selectedStackId !== index) {
              setSelectedStackId(index);
            }
            return isSelectedStacks;
          })
        }
        : { ...stackList[0], features };
      if (!Object.keys(stackToUse).length && stackList.length) {
        stackToUse = { ...stackList[0], features };
        onChange({ stacks: stackToUse.stacks });
      }
      content = (
        <>
          <RecommendedList
            stackList={stackToUse}
            purposesData={vendorsData}
          />
          <Button
            type="secondary"
            data-testid="configureButton"
            onClick={() => setModalOpen(true)}
            disabled={list.length <= 1}
          >
            Configure
          </Button>
        </>
      );
    } else {
      content = <Loader />;
    }
  } else {
    content = <RecommendedList stackList={noStackConfig} purposesData={vendorsData} />;
  }

  const setUserSelectedStack = (value: boolean) => {
    onChange({ userSelectedStack: value });
  };

  const cleanLastSelectedRestrictions = () => {
    setRestrictions({
      publisherConsentRestrictionIds,
      publisherLIRestrictionIds
    });
  };


  const formatDataOnSubmit = () => {
    onChange({
      publisherConsentRestrictionIds: restrictions.publisherConsentRestrictionIds,
      publisherLIRestrictionIds: restrictions.publisherLIRestrictionIds
    });
  };

  const onChangeForm = (data) => {
    setRestrictions({
      publisherLIRestrictionIds: data.legitimate,
      publisherConsentRestrictionIds: data.consent,
    });
  };

  const handleRadios = (event) => {
    event.persist();
    onChange({ use: event.target.value });
  };

  const handleTcfVersion = (event) => {
    event.persist();
    onChangeTCFVersion(Number(event.target.value));
    setCallRecommendationStacks(false);
  };

  return (
    <Card
      title={(
        <h3>
          Customize Purpose Screen &nbsp;
          <span>This allows you to customize how you present the purposes within your CMP</span>
        </h3>
      )}
      extra={<h3 className={Styles.rightSection}>GDPR</h3>}
    >
      <div className={Styles.optionsContainer}>
        <Form.Item
          name="gvlVersion"
          label="TCF Version"
          className={Styles.consentsRadios}
          onChange={handleTcfVersion}
        >
          <Radio.Group
            options={[
              { value: GVL_VERSION_2, label: 'TCF 2.0', disabled: false },
              { value: GVL_VERSION_3, label: 'TCF 2.2', disabled: false },
            ]}
          />
        </Form.Item>
      </div>
      <div className={Styles.optionsContainer}>
        <Form.Item
          name="use"
          className={Styles.consentsRadios}
          onChange={handleRadios}
        >
          <Radio.Group
            options={[
              { value: 'purposes', label: 'Use Purposes', disabled: false },
              { value: 'stacks', label: 'Use Stacks', disabled: false },
            ]}
          />
        </Form.Item>
        <Button
          type="link"
          onClick={() => setRestrictionsModal(true)}
          disabled={!(flexiblePurposes.length)}
        >
          CONFIGURE RESTRICTIONS
        </Button>
      </div>
      {content}
      <Modal
        title="Customize Stacks"
        open={modalOpen}
        closable={false}
        className={Styles.modalSize}
        okText="Save"
        cancelText="Cancel"
        onClose={() => setModalOpen(!modalOpen)}
        onCancel={() => setModalOpen(false)}
        onOk={() => {
          setModalOpen(false);
          setUserSelectedStack(true);
          onChange({ stacks: selectedStack });
        }}
      >
        <Stacks
          purposeIds={purposeIds}
          purposeLegitimateIds={purposeLegitimateIds}
          specialFeatures={specialFeatures}
          specialPurposes={specialPurposes}
          features={features}
          destroyOnClose
          list={stackList}
          pending={pending}
          onChange={stack => setSelectedStack(stack)}
          selectedStack={selectedStackId}
          purposesData={vendorsData}
        />
      </Modal>
      <Modal
        title="Configure restrictions"
        onCancel={() => {
          setRestrictionsModal(false);
          cleanLastSelectedRestrictions();
        }}
        onOk={() => {
          formatDataOnSubmit();
          setRestrictionsModal(false);
        }}
        open={restrictionsModal}
        closable={false}
        destroyOnClose
        className={Styles.modalSize}
        okText="Save"
        cancelText="Cancel"
      >
        <Restrictions
          flexiblePurposes={flexiblePurposes}
          onChange={onChangeForm}
          purposesList={vendorsData.purposes}
          publisherConsentRestrictionIds={restrictions.publisherConsentRestrictionIds}
          publisherLIRestrictionIds={restrictions.publisherLIRestrictionIds}
        />
      </Modal>
    </Card>
  );
};

export default PurposesV2;
