import { GVL_VERSION_3 } from '../../../utils/constants';
import { autoDetectSupportedLanguage, SUPPORTED_LANGUAGES } from '../../../utils/language';
import { formatCustomLinks } from '../../../utils/themes';

const getAdvancedCustomization = (current) => {
  const currentSettings = current.advancedCustomizationSettings;
  if (currentSettings && currentSettings.length > 0) {
    return { advancedCustomizationSettings: currentSettings };
  }
  return { advancedCustomizationSettings: [{ language: 'en', }] };
};

const getConsentOrPayInitData = (current) => {
  const currentSettings = current.consentOrPaySettings;
  if (currentSettings && currentSettings.length > 0) {
    return { consentOrPaySettings: currentSettings };
  }
  return {
    consentOrPaySettings: [
      { language: 'en' }
    ]
  };
};

const initialScreenCustomLinks = (uxInitialScreenCustomLinks) => uxInitialScreenCustomLinks.reduce(
  (acc, ux, index) => (
    {
      ...acc,
      [`uxInitialScreenCustomLinksText${index}`]: ux.text,
      [`uxInitialScreenCustomLinksUrl${index}`]: ux.url
    }
  ), {}
);

export const getFormInitData = (current, values, accountId, pCode) => {
  let persistentConsentButton = true;
  let initScreenButton = true;
  let uxInitialScreenCustomLinks;
  let uxShowRejectButton = 'true';
  const privacyModes = [];
  let v2HasCCPA = false;
  let v2HasGDPR = false;
  let use;

  if (current.privacyModes) {
    if (current.privacyModes.includes('GDPR')) {
      privacyModes.push('GDPR');
      v2HasGDPR = true;
    }
    if (current.privacyModes.includes('USP')) {
      privacyModes.push('USP');
      v2HasCCPA = true;
    }
  } else {
    privacyModes.push('GDPR');
    v2HasGDPR = true;
    privacyModes.push('USP');
    v2HasCCPA = true;
  }

  uxInitialScreenCustomLinks = current.uxInitialScreenCustomLinks && current.uxInitialScreenCustomLinks.length
    ? formatCustomLinks(current.uxInitialScreenCustomLinks)
    : [];

  if ('uxShowRejectButton' in current) {
    uxShowRejectButton = current.uxShowRejectButton.toString();
  }

  if (typeof current.uxPersistentConsentButton === 'boolean') {
    persistentConsentButton = current.uxPersistentConsentButton;
  }
  if (typeof current.initScreenRejectButtonShowing === 'boolean') {
    initScreenButton = current.initScreenRejectButtonShowing;
  }

  if (current.stacks) {
    use = current.stacks.length ? 'stacks' : 'purposes';
  } else {
    use = 'stacks';
  }

  return {
    accountId,
    pCode,
    themeName: current.themeName || '',
    language: current.language || autoDetectSupportedLanguage(),
    privacyModes,
    v2HasGDPR,
    v2HasCCPA,
    displayUi: current.displayUi || 'popup',
    uxSoftOptIns: current.uxSoftOptIns || false,
    uxShowRejectButton,
    uxCustomizedCss: current.uxCustomizedCss || null,
    uxFontColor: current.uxFontColor || null,
    uxLinkColor: current.uxLinkColor || null,
    uxBackgroundColor: current.uxBackgroundColor || null,
    uxToogleActiveColor: current.uxToogleActiveColor || null,
    uxPrimaryButtonColor: current.uxPrimaryButtonColor || null,
    uxPrimaryButtonTextColor: current.uxPrimaryButtonTextColor || null,
    uxSecondaryButtonColor: current.uxSecondaryButtonColor || null,
    uxSecondaryButtonTextColor: current.uxSecondaryButtonTextColor || null,
    initScreenTitleText: current.initScreenTitleText,
    initScreenBodyText: current.initScreenBodyText || '1',
    saveAndExitButton: current.saveAndExitButton || null,
    agreeButton: current.agreeButton || '',
    initScreenRejectButton: current.initScreenRejectButton || null,
    initScreenAcceptButtonText: current.initScreenAcceptButtonText || null,
    initScreenRejectButtonText: current.initScreenRejectButtonText || null,
    initScreenPurposeLinkText: current.initScreenPurposeLinkText || null,
    uxInitialScreenCustomLinks,
    ...initialScreenCustomLinks(uxInitialScreenCustomLinks),
    uxDefaultValueForToggles: current.uxDefaultValueForToggles || 'off',
    purposeScreenTitleText: current.purposeScreenTitleText || null,
    purposeScreenBodyText: current.purposeScreenBodyText || null,
    acceptAllButtonText: current.acceptAllButtonText || null,
    purposeScreenVendorLinkText: current.purposeScreenVendorLinkText || null,
    purposeScreenSaveAndExitButtonText: current.purposeScreenSaveAndExitButtonText || null,
    purposeScreenCancelButtonText: current.purposeScreenCancelButtonText || null,
    vendorScreenSaveAndExitButtonText: current.vendorScreenSaveAndExitButtonText || null,
    uxPersistentConsentButton: persistentConsentButton,
    consentLinkLabel: current.consentLinkLabel || '',
    uspDnsTitleText: current.uspDnsTitleText || '',
    uspDnsText: current.uspDnsText || '',
    uspDeleteDataLinkText: current.uspDeleteDataLinkText || null,
    uspDeleteDataLink: current.uspDeleteDataLink || null,
    uspAccessDataLinkText: current.uspAccessDataLinkText || null,
    uspAccessDataLink: current.uspAccessDataLink || null,
    uspPrivacyPolicyLinkText: current.uspPrivacyPolicyLinkText || null,
    uspPrivacyPolicyLink: current.uspPrivacyPolicyLink || null,
    initialScreen: current.initialScreen || 'SUMMARY',
    initScreenRejectButtonShowing: initScreenButton,
    initScreenCloseButtonShowing: current.initScreenCloseButtonShowing || false,
    suppressCcpaLinks: current.suppressCcpaLinks || false,
    uxPersistentConsentLinkLocation: current.uxPersistentConsentLinkLocation || 'BOTTOM_RIGHT',
    vendorPurposeIds: values.vendorPurposeIds || current.vendorPurposeIds || [],
    vendorPurposeLegitimateInterestIds: current.vendorPurposeLegitimateInterestIds || [],
    vendorSpecialFeaturesIds: current.vendorSpecialFeaturesIds || [],
    stacks: current.stacks || [],
    vendorSpecialPurposesIds: current.vendorSpecialPurposesIds || [],
    vendorFeaturesIds: current.vendorFeaturesIds || [],
    publisherConsentRestrictionIds: current.publisherConsentRestrictionIds || [],
    publisherLIRestrictionIds: current.publisherLIRestrictionIds || [],
    use,
    gvlVersion: current.gvlVersion || GVL_VERSION_3,
    userSelectedStack: current.stacks && current.stacks.length > 0,
    consentOrPayLayout: current.consentOrPayLayout || 'Subscribe',
    ...getAdvancedCustomization(current),
    ...getConsentOrPayInitData(current)
  };
};

export const filterPurposesTCFVersion = (gvlData) => {
  const keysToMap = [
    {
      gvlKey: 'purposes',
      vendorKey: 'vendorPurposeIds'
    },
    {
      gvlKey: 'legIntPurposes',
      vendorKey: 'vendorPurposeLegitimateInterestIds'
    },
    {
      gvlKey: 'features',
      vendorKey: 'vendorFeaturesIds'
    },
    {
      gvlKey: 'specialFeatures',
      vendorKey: 'vendorSpecialFeaturesIds'
    },
    {
      gvlKey: 'specialPurposes',
      vendorKey: 'vendorSpecialPurposesIds'
    },
    {
      gvlKey: 'flexiblePurposes',
      vendorKey: 'vendorFlexiblePurposesIds'
    }
  ];

  return gvlData.vendors
    .filter(vendor => !gvlData.blockedVendors.includes(vendor.id))
    .reduce(
      (acc, vendor) => {
        keysToMap.forEach((key) => {
          if (vendor[key.gvlKey]) {
            vendor[key.gvlKey].forEach((item) => {
              if (!acc[key.vendorKey].includes(item)) {
                acc[key.vendorKey].push(item);
              }
            });
          }
        });
        return acc;
      },
      {
        vendorPurposeIds: [],
        vendorPurposeLegitimateInterestIds: [],
        vendorFeaturesIds: [],
        vendorSpecialFeaturesIds: [],
        vendorSpecialPurposesIds: [],
        vendorFlexiblePurposesIds: []
      }
    );
};

export const getLanguageLabel = (code) => {
  const language = SUPPORTED_LANGUAGES.find(lang => lang.value === code.toLowerCase());
  return language ? language.label : '';
};

export const getDefaultLangs = currentVal => (currentVal ? currentVal.map(item => item.language) : ['en']);

export const getDefaultAddCustomLink = (currentVal, lang) => {
  if (!currentVal) return false;

  const customLinkItem = currentVal.find(item => item.language === lang);
  if (customLinkItem && customLinkItem.customFooterLink) {
    return customLinkItem.customFooterLink.length === 2;
  }
  return false;
};
