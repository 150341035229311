import React, { useState } from "react";
import Form from "antd/es/form";
import Card from "../card";
import InfoIconTooltip from "../infoIconTooltip";
import { Input, Radio } from "antd/es";
import { isFieldEnabled } from "../../../utils/sites";

const { CardHeader, CardContent } = Card;

type Props = {
  gdprPrivacyLink?: string;
  cmpVersion?: string;
};

const VALUES = {
  YES: 1,
  NO: 0
};

function PrivacyLink({ gdprPrivacyLink, cmpVersion }: Props) {
  const [showPrivacyLinkInput, setShowPrivacyLinkInput] = useState(gdprPrivacyLink ? VALUES.YES : VALUES.NO);
  return (
    <>
      {isFieldEnabled("gdprPrivacyLink", cmpVersion) ? (
        <Card>
          <CardHeader title="Publisher Privacy Policy">
            <span className="regulation">GDPR</span>
          </CardHeader>

          <CardContent>
            <Form.Item
              label="SHOW PUBLISHER PRIVACY POLICY LINK ON THE CONSENT"
              tooltip={{
                title: "Publisher privacy policy",
                icon: (
                  <span>
                    <InfoIconTooltip />
                  </span>
                )
              }}
            >
              <Radio.Group
                name="show_publisher_policy"
                value={showPrivacyLinkInput}
                onChange={e => setShowPrivacyLinkInput(e.target.value)}
              >
                <Radio value={VALUES.YES}>Yes</Radio>
                <Radio value={VALUES.NO}>No</Radio>
              </Radio.Group>
            </Form.Item>

            {showPrivacyLinkInput == VALUES.YES ? (
              <Form.Item
                label="PUBLISHER PRIVACY POLICY"
                rules={[
                  {
                    required: true,
                    message: "Privacy policy is required"
                  }
                ]}
                name="gdprPrivacyLink"
              >
                <Input />
              </Form.Item>
            ) : null}
          </CardContent>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

export default PrivacyLink;
